import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import AOS from "aos";
import "aos/dist/aos.css";
import Select from "react-select";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { all_routes } from "../router/all_routes";
import { Dropdown } from "primereact/dropdown";
import { COffcanvasTitle } from "@coreui/react";
import { COffcanvasHeader } from "@coreui/react";
import { COffcanvasBody } from "@coreui/react";
import { COffcanvas } from "@coreui/react";
import { CButton, CCloseButton } from "@coreui/react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, IMG_URL } from "../../ApiUrl";
import Swal from "sweetalert2";

interface Coach {
  first_name: string;
  last_name: string;
  _id: number;
  price: number;
  profile_picture: string;
  src: string;
}

interface Trainer {
  trainer_name: string;
  duration: string;
  focus_area: string;
  price: number;
  _id: number;
  profile_picture: string[];
  src: string;
}

interface Venues {
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  activities: string;
  category: string;
  _id: string;
  images: string;
  src: string;
}

interface Goto {
  name: string;
}

const Home = () => {
  const routes = all_routes;
  const [selectedTimeframe, setSelectedTimeframe] = useState<Goto[]>([]);
  const [error, setError] = useState("");
  const [selectedItems, setSelectedItems] = useState(Array(9).fill(false));
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [trainer, setTrainer] = useState<Trainer[]>([]);
  const [venues, setVenues] = useState<Venues[]>([]);
  const [selectedSort, setSelectedSort] = useState<string>();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [location, setLocation] = useState(null); 

  // const { id } = props;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    first_name: "",
    mobile: "",
    email: "",
    subject: "Quick Enquiry",
    comments: "",
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const showLoadingAlert = () => {
    Swal.fire({
      title: "Loading",
      html: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setTimeout(() => {
      Swal.close();
    }, 1000);
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("onchange->>data>>>", e.target);
    if (name === "first_name") {
      if (/^[a-zA-Z\s]+$/.test(value) || value === "") {
        setInput((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setError(`${name} must contain only letters`);
      }
    } else if (name === "phone") {
      if (/^\d{0,10}$/.test(value) || value === "") {
        setInput((prevState) => ({
          ...prevState,
          mobile: value,
        }));
      } else {
        setError("Mobile number must be 10 digits");
      }
    } else if (name === "email") {
      setInput((prevState) => ({
        ...prevState,
        email: value,
      }));
    } else {
      setInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInquiries = async (e: any) => {
    e.preventDefault();
    try {
      console.log(input, "input data hai");
      if (!input.first_name || !input.mobile || !input.comments) {
        setError("Please fill in all required fields.");
        alert("Please fill in all required fields.");
        return;
      }

      const response = await axios
        .post(`${API_URL}/enquiry/create`, input)
        .then((response) => {
          // alert("response");
          showLoadingAlert();
          setVisible(false);
          setTimeout(function () {
            Swal.fire(
              "Success!",
              "Your enquiry has been submitted!",
              "success"
            );
          }, 1000);

          //   navigate("/home");

          if (response) {
            setInput({
              first_name: "",
              mobile: "",
              email: "",
              subject: "Quick Enquiry",
              comments: "",
            });
          }
        });
    } catch (error) {
      console.error("Error:", error);

      setError("Error: " + error);
    }
  };

  const timeframeOptions = [{ name: "Courts" }, { name: "Coaches" }];
  const sortOptions = [{ name: "Indore" }];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const images = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const options = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const locationOptions = [
    { value: "germany", label: "Germany" },
    { value: "russia", label: "Russia" },
    { value: "france", label: "France" },
    { value: "uk", label: "UK" },
    { value: "colombia", label: "Colombia" },
  ];

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });

    const fetchCoaches = async () => {
      try {
        const response = await axios.get(`${API_URL}/fetch-all-coaches`);
        const coachData = response.data.data;
        console.log("Response:", response);

        const mappedData = coachData.map((coach: any) => ({
          first_name: coach.first_name,
          last_name: coach.last_name,
          _id: coach._id,
          price: coach.price,
          profile_picture: coach.profile_picture,
        }));
        setCoaches(mappedData);
      } catch (error) {
        console.error("Error fetching coaches:", error);
      }
    };

    fetchCoaches();

    const fetchVenues = async () => {
      try {
        const response = await axios.get(`${API_URL}/venue/getVenue`);
        const venuesData = response.data.venue;
        console.log("Response:", response);
        const mappedData = venuesData.map((venues: any) => ({
          name: venues.name,
          address: venues.address,
          city: venues.city,
          state: venues.state,
          zipcode: venues.zipcode,
          activities: venues.activities,
          images: venues.images,
          category: venues.category,
          _id: venues._id,
          // profile: coach.profile
        }));
        setVenues(mappedData);
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };

    fetchVenues();
  }, []);

  coaches.forEach((coach) => {
    console.log("Coach ID:", coach?.profile_picture[0]);
  });

  useEffect(() => {
    // Fetch coach data from API
    const fetchTrainer = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/PersonalTraining/fetchAll`
        );
        const trainerData = response.data.data;
        console.log("Response:", response);

        const mappedData = trainerData.map((trainer: any) => ({
          trainer_name: trainer.trainer_name,
          duration: trainer.duration,
          focus_area: trainer.focus_area,
          price: trainer.price,
          _id: trainer._id,
          profile_picture: trainer.profile_picture,
        }));
        setTrainer(mappedData);
      } catch (error) {
        console.error("Error fetching trainer:", error);
      }
    };

    fetchTrainer();
  }, []);

  console.log("this is selected-=-=-=", selectedTimeframe)
  const navigateToPage = () => {
    if (selectedTimeframe.name === "Coaches") {
      navigate("/coaches")
    } else {
      navigate("/sports-venue")
    }
  }

  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  return (
    <>
      <div>
        {location ? (
          <div>
            <p>Your current location is:</p>
            {/* <p>Latitude: {location.latitude}</p> */}
            {/* <p>Longitude: {location.longitude}</p> */}
            {/* You can customize the pop-up with this information */}
          </div>
        ) : (
          <p>Loading location...</p>
        )}
      </div>

      <section className="hero-section">
        <div className="banner-cock-one">
          {/* <ImageWithBasePath
            src="assets/img/icons/banner-cock1.svg"
            alt="Banner"
          /> */}
        </div>
        <div className="banner-shapes">
          <div className="banner-dot-one">
            <span />
          </div>
          <div className="banner-cock-two">
            <ImageWithBasePath src="assets/img/new-img10.png" alt="Banner" />
            <span />
          </div>
          <div className="banner-dot-two">
            <span />
          </div>
        </div>
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center w-100">
              <div className="col-lg-7 col-md-10 mx-auto">
                <div className="section-search aos" data-aos="fade-up">
                  <h4>World Class Coaches &amp; Premium Courts</h4>
                  <h1>
                    Choose Your <span>Coaches</span> and Start Your Training
                  </h1>
                  <p className="sub-info">
                    Unleash Your Athletic Potential with Expert Coaching,
                    State-of-the-Art Facilities, and Personalized Training
                    Programs.
                  </p>
                  <div className="search-box">
                    <form onSubmit={navigateToPage}>
                      <div className="search-input line">
                        <div className="form-group mb-0">
                          <label>Search for</label>

                          <Dropdown
                            value={selectedTimeframe}
                            onChange={(e) => setSelectedTimeframe(e.value)}
                            options={timeframeOptions}
                            optionLabel="name"
                            placeholder="court"
                            className="select custom-select-list"
                          />
                        </div>
                      </div>
                      <div className="search-input">
                        <div className="form-group mb-0">
                          <label>Where </label>
                          <Dropdown
                            value={selectedSort}
                            onChange={(e) => setSelectedSort(e.value)}
                            options={sortOptions}
                            optionLabel="name"
                            placeholder="Choose Location"
                            className="select custom-select-list w-100"
                          />
                        </div>
                      </div>
                      <div className="search-btn">
                        <button className="btn" type="submit">
                          <i className="feather-search" />
                          <span className="search-text">Search</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-imgs text-center aos" data-aos="fade-up">
                  <ImageWithBasePath
                    className="img-fluid"
                    src="assets/img/bg/banner-right.png"
                    alt="Banner"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="enquiry-btn">
                <CButton
                  color="primary"
                  onClick={() => setVisible(true)}
                  style={{
                    writingMode: "vertical-lr",
                    textOrientation: "upright",
                    padding: "12px 10px",
                  }}
                >
                  ENQUIRY
                </CButton>
              </div>
              <COffcanvas
                placement="end"
                scroll={true}
                visible={visible}
                onHide={() => setVisible(false)}
              >
                <COffcanvasHeader>
                  <COffcanvasTitle>ENQUIRY</COffcanvasTitle>
                  <CCloseButton
                    className="text-reset"
                    onClick={() => setVisible(false)}
                  />
                </COffcanvasHeader>
                <COffcanvasBody>
                  <form
                    className="contact-us enquiry"
                    onSubmit={handleInquiries}
                  >
                    <div className="row">
                      <div className="col mb-3">
                        {/* <label htmlFor="subject" className="form-label">
                                    Name
                                </label> */}
                        <input
                          type="text"
                          className="form-control"
                          id="full-name"
                          name="first_name"
                          placeholder=" Full Name"
                          value={input.first_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-3">
                        {/* <label htmlFor="subject" className="form-label">
                                    Phone number
                                </label> */}
                        <input
                          type="number"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          value={input.mobile}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-3">
                        {/* <label htmlFor="subject" className="form-label">
                                    E-mail
                                </label> */}
                        <input
                          type="text"
                          className="form-control"
                          id="e-mail"
                          name="email"
                          placeholder="E-mail  Adress"
                          value={input.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div>
                      {/* <label htmlFor="comments" className="form-label">
                                Comments
                            </label> */}
                      <textarea
                        className="form-control"
                        id="comments"
                        name="comments"
                        rows={3}
                        placeholder="Message"
                        value={input.comments}
                        onChange={handleInputChange}
                        // defaultValue={""}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-secondary d-flex align-items-center"
                      // onClick={handleInquiries}
                    >
                      Submit
                      <i className="feather-arrow-right-circle ms-2" />
                    </button>
                  </form>
                </COffcanvasBody>
              </COffcanvas>
            </div>
          </div>
        </div>
      </section>
      <section className="section work-section">
        <div className="work-cock-img">
          {/* <ImageWithBasePath src="assets/img/icons/work-cock.svg" alt="Icon" /> */}
        </div>
        <div className="work-img">
          <div className="work-img-right">
            <ImageWithBasePath src="assets/img/new-img01.png" alt="Icon" />
            {/* <ImageWithBasePath src="assets/img/bg/work-bg.png" alt="Icon" /> */}
          </div>
        </div>
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              How It <span>Works</span>
            </h2>
            <p className="sub-title">
              Simplifying the booking process for coaches, venues, and athletes.
            </p>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/work-icon1.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <Link to={routes.register}>Join Us</Link>
                  </h5>
                  <p>
                    Quick and Easy Registration: Get started on our software
                    platform with a simple account creation process.
                  </p>
                  <Link className="btn" to={routes.register}>
                    Register Now <i className="feather-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/work-icon2.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <Link to={routes.coachesGrid}>Select Coaches</Link>
                  </h5>
                  <p>
                    Book coaches for expert guidance and premium facilities.
                    Enjoy a seamless experience on our platform.
                  </p>
                  <Link className="btn" to={routes.coachesGrid}>
                    Go To Coaches <i className="feather-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/work-icon3.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <Link to={routes.blogListSidebarLeft}>Select Venues</Link>
                  </h5>
                  <p>
                    Easily book venues, pay, and enjoy a seamless experience on
                    our user-friendly platform.
                  </p>
                  <Link className="btn" to={routes.blogListSidebarLeft}>
                    Go To Venues <i className="feather-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Rental Deals */}
      <section className="section featured-venues">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Featured <span>Venues</span>
            </h2>
            <p className="sub-title">
              Advanced sports venues offer the latest facilities, dynamic and
              unique environments for enhanced badminton performance.
            </p>
          </div>
          <div className="row">
            <div className="featured-slider-group ">
              <div className="owl-carousel featured-venues-slider owl-theme">
                <Slider {...settings}>
                  {/* Featured Item */}
                  {venues.map((venue, index) => (
                    <div className="featured-venues-item" key={index}>
                      <div className="listing-item home-venue">
                        <div className="listing-img">
                          <Link to={`/sports-venue/venue-details/${venue._id}`}>
                            <ImageWithBasePath
                              // src="/assets/img/blog/blog-01.jpg"
                              src={
                                venue.images?.src
                                  ? `${IMG_URL}/${venue?.images?.src}`
                                  : "assets/img/no_image.png"
                              }
                              className="img-fluid"
                              alt="Venue"
                            />
                          </Link>
                          <div className="fav-item-venues news-sports">
                            <span className="tag tag-blue">
                              {venue.category &&
                                venue.category
                                  .split(",")
                                  .map((category) => category.trim())
                                  .join(", ")}
                            </span>

                            <div className="list-reviews coche-star">
                              <Link
                                to="#"
                                className={`fav-icon ${selectedItems[3] ? "selected" : ""}`}
                                key={3}
                                onClick={() => handleItemClick(3)}
                              >
                                <i className="feather-heart" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="listing-content news-content">
                          <div className="listing-venue-owner">
                            <div className="navigation">
                              {/* <Link to="#">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-01.jpg"
                                alt="User"
                              />
                              Orlando Waters
                            </Link> */}
                              {venue.activities}
                              <span>
                                {/* <i className="feather-calendar" />
                              15 May 2023 */}
                              </span>
                            </div>
                          </div>
                          <h3 className="listing-title">
                            <Link
                              to={`/sports-venue/venue-details/${venue._id}`}
                            >
                              {venue.name}{" "}
                            </Link>
                          </h3>
                          <p>
                            <i className="feather-map-pin me-2" />
                            {venue.address} , {venue.city} , {venue.state} ,{" "}
                            {venue.zipcode}
                          </p>
                          {/* <div className="listing-button read-new">
                          <ul className="nav">
                            <li>
                              <Link to="#">
                                <i className="feather-heart" />
                                45
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="feather-message-square" />
                                40
                              </Link>
                            </li>
                          </ul>
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/clock.svg"
                              alt=""
                            />
                            10 Min To Read
                          </span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* /Featured Item */}
                </Slider>
              </div>
            </div>
          </div>
          {/* View More */}
          <div className="view-all text-center aos" data-aos="fade-up">
            <Link
              to={routes.blogListSidebarLeft}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View All Featured
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
          {/* View More */}
        </div>
      </section>
      {/* /Rental Deals */}

      {/* Services */}
      {/* <section className="section service-section">
        <div className="work-cock-img">
          <ImageWithBasePath
            src="assets/img/icons/work-cock.svg"
            alt="Service"
          />
        </div>
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Explore Our <span>Services</span>
            </h2>
            <p className="sub-title">
              Fostering excellence and empowering sports growth through tailored
              services for athletes, coaches, and enthusiasts.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-grid w-100 aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.blogListSidebarLeft}>
                    <ImageWithBasePath
                      src="assets/img/services/service-01.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="service-content">
                  <h4>
                    <Link to={routes.blogListSidebarLeft}>Court Rent</Link>
                  </h4>
                  <Link to={routes.blogListSidebarLeft}>Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-grid w-100 aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetail}>
                    <ImageWithBasePath
                      src="assets/img/services/service-02.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="service-content">
                  <h4>
                    <Link to={routes.serviceDetail}>Group Lesson</Link>
                  </h4>
                  <Link to={routes.serviceDetail}>Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-grid w-100 aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.coachesGrid}>
                    <ImageWithBasePath
                      src="assets/img/services/service-03.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="service-content">
                  <h4>
                    <Link to={routes.coachesGrid}>Training Program</Link>
                  </h4>
                  <Link to={routes.coachesGrid}>Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-grid w-100 aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.blogList}>
                    <ImageWithBasePath
                      src="assets/img/services/service-04.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="service-content">
                  <h4>
                    <Link to={routes.blogList}>Private Lessons</Link>
                  </h4>
                  <Link to={routes.blogList}>Learn More</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="view-all text-center aos" data-aos="fade-up">
            <Link
              to={routes.services}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View All Services{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </section> */}
      {/* /Services */}

      {/* Convenient */}
      <section className="section convenient-section">
        <div className="cock-img">
          <div className="cock-img-one">
            {/* <ImageWithBasePath src="assets/img/icons/cock-01.svg" alt="Icon" /> */}
          </div>
          <div className="cock-img-two">
            {/* <ImageWithBasePath src="assets/img/icons/cock-02.svg" alt="Icon" /> */}
          </div>
          <div className="cock-circle">
            <ImageWithBasePath src="assets/img/new-img08.png" alt="Icon" />
          </div>
        </div>
        <div className="container">
          <div className="convenient-content aos" data-aos="fade-up">
            <h2>Convenient &amp; Flexible Scheduling</h2>
            <p>
              Find and book coaches conveniently with our online system that
              matches your schedule and location.
            </p>
          </div>
          <div className="convenient-btns aos" data-aos="fade-up">
            <Link
              to={routes.blogList}
              className="btn btn-primary d-inline-flex align-items-center"
            >
              Book a Training{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
            <Link
              to={routes.coachesGrid}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              Book a Coach{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </section>
      {/* /Convenient */}

      {/* Featured Coaches */}
      <section className="section featured-section">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Featured <span>Coaches</span>
            </h2>
            <p className="sub-title">
              Uplift your badminton game with our featured coaches, personalized
              instruction, and expertise to achieve your goals.
            </p>
          </div>
          <div className="row">
            <div className="featured-slider-group aos" data-aos="fade-up">
              <div className="owl-carousel featured-coache-slider owl-theme">
                <Slider {...options}>
                  {/* Featured Item */}
                  {coaches.map((coach, index) => (
                    <div className="featured-venues-item" key={index}>
                      <div className="listing-item mb-0">
                        <div
                          className="listing-img"
                          style={{ height: "231px" }}
                        >
                          <Link to={`/coaches/coach-detail/${coach._id}`}>
                            <>{console.log("hiiiiiiiiiiiiiiiiiiii", coach)}</>
                            <ImageWithBasePath
                              src={
                                coach.profile_picture[0]?.src
                                  ? `${IMG_URL}/${coach.profile_picture[0]?.src}`
                                  : "assets/img/no_image.png"
                              }
                              alt="User"
                            />
                          </Link>
                          <div className="fav-item-venues">
                            <span className="tag tag-blue">Rookie</span>
                            <div className="list-reviews coche-star">
                              <Link to="#" className="fav-icon">
                                <i className="feather-heart" />
                              </Link>
                            </div>
                          </div>
                          <div className="hour-list">
                            <h5 className="tag tag-primary">
                              From $250 <span>/hr</span>
                            </h5>
                          </div>
                        </div>
                        <div className="listing-content list-coche-content">
                          <span>4 Lessons</span>
                          <h3>
                            {/* <Link to={routes.coachDetail}>Kevin Anderson</Link> */}

                            <Link to={`/coaches/coach-detail/${coach._id}`}>
                              {coach.first_name} {coach.last_name}
                            </Link>
                          </h3>
                          <Link to={`/coaches/coach-detail/${coach._id}`}>
                            <i className="feather-arrow-right" />
                          </Link>
                          <Link
                            to={`/coaches/coach-detail/${coach._id}`}
                            className="icon-hover"
                          >
                            <i className="feather-calendar" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div className="view-all text-center aos" data-aos="fade-up">
            <Link
              to={routes.coachesGrid}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View All Coaches{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </section>
      {/* /Featured Coaches */}

      {/* Journey */}
      {/* <section className="section journey-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="start-your-journey aos" data-aos="fade-up">
                <h2>
                  Start Your Journey With{" "}
                  <span className="active-sport">KheloIndore</span> Badminton
                  Today.
                </h2>
                <p>
                  At KheloIndore Badminton, we prioritize your satisfaction and
                  value your feedback as we continuously improve and evolve our
                  learning experiences.
                </p>
                <p>
                  Our instructors utilize modern methods for effective badminton
                  lessons, offering introductory sessions for beginners and
                  personalized development plans to foster individual growth.
                </p>
                <span className="stay-approach">
                  Stay Ahead With Our Innovative Approach:
                </span>
                <div className="journey-list">
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Skilled Professionals
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Modern Techniques
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Intro Lesson
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Personal Development
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Advanced Equipment
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Interactive Classes For Easy Learning.
                    </li>
                  </ul>
                </div>
                <div className="convenient-btns">
                  <Link
                    to={routes.register}
                    className="btn btn-primary d-inline-flex align-items-center"
                  >
                    <span>
                      <i className="feather-user-plus me-2" />
                    </span>
                    Join With Us
                  </Link>
                  <Link
                    to={routes.aboutUs}
                    className="btn btn-secondary d-inline-flex align-items-center"
                  >
                    <span>
                      <i className="feather-align-justify me-2" />
                    </span>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="journey-img aos" data-aos="fade-up">
                <ImageWithBasePath
                  src="assets/img/journey-01.png"
                  className="img-fluid"
                  alt="User"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Journey */}

      {/* Group Coaching */}
      {/* <section className="section group-coaching">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Our <span>Features</span>
            </h2>
            <p className="sub-title">
              Discover your potential with our comprehensive training, expert
              trainers, and advanced facilities. Join us to improve your
              athletic career.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-01.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Group Coaching</h3>
                  <p>
                    Accelerate your skills with tailored group coaching sessions
                    for badminton players game.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-02.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Private Coaching</h3>
                  <p>
                    Find private badminton coaches and academies for a
                    personalized approach to skill enhancement.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-03.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Equipment Store</h3>
                  <p>
                    Your one-stop shop for high-quality badminton equipment,
                    enhancing your on-court performance.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-04.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Innovative Lessons</h3>
                  <p>
                    Enhance your badminton skills with innovative lessons,
                    combining modern techniques and training methods
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-05.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Badminton Community</h3>
                  <p>
                    Upraise your game with engaging lessons and a supportive
                    community. Join us now and take your skills to new heights.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <ImageWithBasePath
                      src="assets/img/icons/coache-icon-06.svg"
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Court Rental</h3> 
                  <p>
                    Enjoy uninterrupted badminton sessions at KheloIndorewith
                    our premium court rental services.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Group Coaching */}

      {/* Earn Money */}
      <section className="section earn-money">
        <div className="cock-img cock-position">
          <div className="cock-img-one ">
            {/* <ImageWithBasePath src="assets/img/icons/cock-01.svg" alt="Icon" /> */}
          </div>
          <div className="cock-img-two">
            {/* <ImageWithBasePath src="assets/img/icons/cock-02.svg" alt="Icon" /> */}
          </div>
          <div className="cock-circle">
            {/* <ImageWithBasePath src="assets/img/bg/cock-shape.png" alt="Icon" /> */}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="private-venue aos" data-aos="fade-up">
                <div className="convenient-btns become-owner " role="tablist">
                  <Link
                    to={routes.register}
                    className="btn btn-secondary become-venue d-inline-flex align-items-center nav-link active"
                    id="nav-Recent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-Recent"
                    role="tab"
                    aria-controls="nav-Recent"
                    aria-selected="true"
                  >
                    Become A Venue Member
                  </Link>
                  <Link
                    to={routes.register}
                    className="btn btn-primary become-coche d-inline-flex align-items-center nav-link"
                    id="nav-RecentCoaching-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-RecentCoaching"
                    role="tab"
                    aria-controls="nav-RecentCoaching"
                    aria-selected="false"
                  >
                    Become A Coach
                  </Link>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="nav-Recent"
                    role="tabpanel"
                    aria-labelledby="nav-Recent-tab"
                    tabIndex={0}
                  >
                    <h2>
                      Earn Money Renting Out Your Private Coaches On KheloIndore
                    </h2>
                    <p>
                      Join our network of private facility owners, offering
                      rentals to local players, coaches, and teams.
                    </p>
                    <div className="earn-list">
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          $1,000,000 liability insurance{" "}
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          Build of Trust
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          Protected Environment for Your Activities{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="convenient-btns">
                      <Link
                        to={routes.register}
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <span className="lh-1">
                          <i className="feather-user-plus me-2" />
                        </span>
                        Join With Us
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show "
                    id="nav-RecentCoaching"
                    role="tabpanel"
                    aria-labelledby="nav-Recent-tab"
                    tabIndex={0}
                  >
                    <h2>
                      Earn Money Renting Out Your Private Coaches On KheloIndore
                    </h2>
                    <p>
                      Join our network of private facility owners, offering
                      rentals to local players, coaches, and teams.
                    </p>
                    <div className="earn-list">
                      <ul>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          $1,000,000 liability insurance{" "}
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          Build of Trust
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check " />
                          Protected Environment for Your Activities{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="convenient-btns">
                      <Link
                        to={routes.register}
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <span className="lh-1">
                          <i className="feather-user-plus me-2" />
                        </span>
                        Join With Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Earn Money */}

      {/* Courts Near */}
      <section className="section court-near">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Featured <span>Personal Training</span>
            </h2>
            <p className="sub-title">
              Discover Personal Trainer for convenient and accessible gameplay.
            </p>
          </div>
          <div className="row">
            <div className="featured-slider-group aos" data-aos="fade-up">
              <div className="owl-carousel featured-coache-slider owl-theme">
                <Slider {...options}>
                  {/* Featured Item */}
                  {trainer.map((trainer, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="featured-venues-item">
                        <div className="listing-item listing-item-grid">
                          <div
                            className="listing-img"
                            style={{ height: "316px" }}
                          >
                            {/* <Link to={routes.coachDetail}>
                        <ImageWithBasePath
                          src={`assets/img/featured/${coach.profile}`}
                          alt="Venue"
                        />
                      </Link> */}

                            <Link
                              to={`/personal-training/trainer/${trainer._id}`}
                            >
                              <ImageWithBasePath
                                src={
                                  trainer.profile_picture
                                    ? `${IMG_URL}/${trainer.profile_picture}`
                                    : "assets/img/no_image.png"
                                }
                                alt="user"
                              />
                            </Link>

                            <div
                              className="fav-item-venues"
                              onClick={() => handleItemClick(index)}
                            >
                              <span className="tag tag-blue">Professional</span>
                              <div className="list-reviews coche-star">
                                <Link
                                  to="#"
                                  className={`fav-icon ${
                                    selectedItems[index] ? "selected" : ""
                                  }`}
                                >
                                  <i className="feather-heart" />
                                </Link>
                              </div>
                            </div>
                            <div className="hour-list">
                              <h5 className="tag tag-primary">
                                ${trainer.price} <span>/hr</span>
                              </h5>
                            </div>
                          </div>
                          <div className="listing-content">
                            <h3 className="listing-title">
                              <Link
                                to={`/personal-training/trainer/${trainer._id}`}
                              >
                                {trainer.trainer_name}
                              </Link>
                            </h3>
                            <ul className="mb-2">
                              <li>
                                <span>Duration: {trainer.duration}</span>
                              </li>
                            </ul>
                            <div className="listing-details-group">
                              <p> Focus-Area: {trainer.focus_area}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* View More */}
          <div className="view-all text-center aos" data-aos="fade-up">
            <Link
              to={routes.blogList}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View All Services{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
          {/* View More */}
        </div>
      </section>
      {/* /Courts Near */}

      {/* Testimonials */}
      <section className="section our-testimonials">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Our <span>Testimonials</span>
            </h2>
            <p className="sub-title">
              Glowing testimonials from passionate badminton enthusiasts
              worldwide, showcasing our exceptional services.
            </p>
          </div>
          <div className="row">
            <div className="featured-slider-group aos" data-aos="fade-up">
              <div className="owl-carousel testimonial-slide featured-venues-slider owl-theme">
                <Slider {...settings}>
                  <div className="testimonial-group">
                    <div className="testimonial-review">
                      <div className="rating-point">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span> 5.0</span>
                      </div>
                      <h5>Personalized Attention</h5>
                      <p>
                        KheloIndore coaching services enhanced my badminton
                        skills. Personalized attention from knowledgeable
                        coaches propelled my game to new heights.
                      </p>
                    </div>
                    <div className="listing-venue-owner">
                      <Link className="navigation" to={""}>
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-01.jpg"
                          alt="User"
                        />
                      </Link>
                      <div className="testimonial-content">
                        <h5>
                          <Link to="#">Ariyan Rusov</Link>
                        </h5>
                        <Link to="#" className="btn btn-primary ">
                          Badminton
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="testimonial-group">
                    <div className="testimonial-review">
                      <div className="rating-point">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span> 5.0</span>
                      </div>
                      <h5>Quality Matters !</h5>
                      <p>
                        KheloIndore advanced badminton equipment has greatly
                        improved my performance on the court. Their quality
                        range of rackets and shoes made a significant impact.
                      </p>
                    </div>
                    <div className="listing-venue-owner">
                      <Link className="navigation" to={""}>
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-04.jpg"
                          alt="User"
                        />
                      </Link>
                      <div className="testimonial-content">
                        <h5>
                          <Link to="#">Darren Valdez</Link>
                        </h5>
                        <Link to="#" className="btn btn-primary ">
                          Badminton
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="testimonial-group">
                    <div className="testimonial-review">
                      <div className="rating-point">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span> 5.0</span>
                      </div>
                      <h5>Excellent Professionalism !</h5>
                      <p>
                        KheloIndore unmatched professionalism and service
                        excellence left a positive experience. Highly
                        recommended for court rentals and equipment purchases.
                      </p>
                    </div>
                    <div className="listing-venue-owner">
                      <Link className="navigation" to={""}>
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-03.jpg"
                          alt="User"
                        />
                      </Link>
                      <div className="testimonial-content">
                        <h5>
                          <Link to="#">Elinor Dunn</Link>
                        </h5>
                        <Link to="#" className="btn btn-primary ">
                          Badminton
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="testimonial-group">
                    <div className="testimonial-review">
                      <div className="rating-point">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span> 5.0</span>
                      </div>
                      <h5>Quality Matters !</h5>
                      <p>
                        KheloIndore advanced badminton equipment has greatly
                        improved my performance on the court. Their quality
                        range of rackets and shoes made a significant impact.
                      </p>
                    </div>
                    <div className="listing-venue-owner">
                      <Link className="navigation" to={""}>
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-04.jpg"
                          alt="User"
                        />
                      </Link>
                      <div className="testimonial-content">
                        <h5>
                          <Link to="#">Darren Valdez</Link>
                        </h5>
                        <Link to="#" className="btn btn-primary ">
                          Badminton
                        </Link>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            {/* <div className="brand-slider-group aos" data-aos="fade-up">
              <div className="owl-carousel testimonial-brand-slider owl-theme">
                <Slider {...images}>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-01.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-04.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-03.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-04.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-05.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-03.svg"
                      alt="Brand"
                    />
                  </div>
                  <div className="brand-logos">
                    <ImageWithBasePath
                      src="assets/img/testimonial-icon-04.svg"
                      alt="Brand"
                    />
                  </div>
                </Slider>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section className="section featured-plan">
        <div className="work-img ">
          <div className="work-img-right">
            <ImageWithBasePath src="assets/img/bg/work-bg.png" alt="Icon" />
          </div>
        </div>
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              We Have Excellent <span>Plans For You</span>
            </h2>
            <p className="sub-title">
              Choose monthly or yearly plans for uninterrupted access to our
              premium badminton facilities. Join us and experience convenient
              excellence.
            </p>
          </div>
          <div className="interset-btn aos" data-aos="fade-up">
            <div className="status-toggle d-inline-flex align-items-center">
              Monthly
              <input type="checkbox" id="status_1" className="check" />
              <label htmlFor="status_1" className="checktoggle">
                checkbox
              </label>
              Yearly
            </div>
          </div>
          <div className="price-wrap aos" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-lg-4 d-flex col-md-6">
                
                <div className="price-card flex-fill ">
                  <div className="price-head">
                    <ImageWithBasePath
                      src="assets/img/icons/price-01.svg"
                      alt="Price"
                    />
                    <h3>Professoinal</h3>
                  </div>
                  <div className="price-body">
                    <div className="per-month">
                      <h2>
                        <sup>$</sup>
                        <span>60.00</span>
                      </h2>
                      <span>Per Month</span>
                    </div>
                    <div className="features-price-list">
                      <h5>Features</h5>
                      <p>Everything in our free Upto 10 users. </p>
                      <ul>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Quality Checked By Envato
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Future Updates
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Technical Support
                        </li>
                        <li className="inactive">
                          <i className="feather-x-circle" />
                          Add Listing{" "}
                        </li>
                        <li className="inactive">
                          <i className="feather-x-circle" />
                          Approval of Listing
                        </li>
                      </ul>
                    </div>
                    <div className="price-choose">
                      <Link to="#" className="btn viewdetails-btn">
                        Choose Plan
                      </Link>
                    </div>
                    <div className="price-footer">
                      <p>
                        Use, by you or one client, in a single end product which
                        end users. charged for. The total price includes the
                        item price and a buyer fee.
                      </p>
                    </div>
                  </div>
                </div>
               
              </div>
              <div className="col-lg-4 d-flex col-md-6">
                
                <div className="price-card flex-fill">
                  <div className="price-head expert-price">
                    <ImageWithBasePath
                      src="assets/img/icons/price-02.svg"
                      alt="Price"
                    />
                    <h3>Expert</h3>
                    <span>Recommended</span>
                  </div>
                  <div className="price-body">
                    <div className="per-month">
                      <h2>
                        <sup>$</sup>
                        <span>60.00</span>
                      </h2>
                      <span>Per Month</span>
                    </div>
                    <div className="features-price-list">
                      <h5>Features</h5>
                      <p>Everything in our free Upto 10 users. </p>
                      <ul>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Quality Checked By Envato
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Future Updates
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />6 Months
                          Technical Support
                        </li>
                        <li className="inactive">
                          <i className="feather-x-circle" />
                          Add Listing{" "}
                        </li>
                        <li className="inactive">
                          <i className="feather-x-circle" />
                          Approval of Listing
                        </li>
                      </ul>
                    </div>
                    <div className="price-choose active-price">
                      <Link to="#" className="btn viewdetails-btn">
                        Choose Plan
                      </Link>
                    </div>
                    <div className="price-footer">
                      <p>
                        Use, by you or one client, in a single end product which
                        end users. charged for. The total price includes the
                        item price and a buyer fee.
                      </p>
                    </div>
                  </div>
                </div>
              
              </div>
              <div className="col-lg-4 d-flex col-md-6">
                
                <div className="price-card flex-fill">
                  <div className="price-head">
                    <ImageWithBasePath
                      src="assets/img/icons/price-03.svg"
                      alt="Price"
                    />
                    <h3>Enterprise</h3>
                  </div>
                  <div className="price-body">
                    <div className="per-month">
                      <h2>
                        <sup>$</sup>
                        <span>990.00</span>
                      </h2>
                      <span>Per Month</span>
                    </div>
                    <div className="features-price-list">
                      <h5>Features</h5>
                      <p>Everything in our free Upto 10 users. </p>
                      <ul>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Quality Checked By Envato
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Included : Future Updates
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Technical Support
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Add Listing{" "}
                        </li>
                        <li className="active">
                          <i className="feather-check-circle" />
                          Approval of Listing
                        </li>
                      </ul>
                    </div>
                    <div className="price-choose">
                      <Link to="#" className="btn viewdetails-btn">
                        Choose Plan
                      </Link>
                    </div>
                    <div className="price-footer">
                      <p>
                        Use, by you or one client, in a single end product which
                        end users. charged for. The total price includes the
                        item price and a buyer fee.
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section featured-venues latest-news">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              The Latest <span>News</span>
            </h2>
            <p className="sub-title">
              Get the latest buzz from the badminton world- stay informed and
              inspired by the thrilling updates and remarkable achievements in
              the sport.
            </p>
          </div>
          <div className="row">
            <div className="featured-slider-group ">
              <div className="owl-carousel featured-venues-slider owl-theme">
                <Slider {...settings}>
                 
                  <div className="featured-venues-item aos" data-aos="fade-up">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            src="assets/img/venues/venues-07.jpg"
                            alt="User"
                          />
                        </Link>
                        <div className="fav-item-venues news-sports">
                          <span className="tag tag-blue">Badminton</span>
                          <div className="list-reviews coche-star">
                            <Link to="#" className="fav-icon">
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="listing-content news-content">
                        <div className="listing-venue-owner listing-dates">
                          <Link to="#" className="navigation">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-01.jpg"
                              alt="User"
                            />
                            Orlando Waters
                          </Link>
                          <span>
                            <i className="feather-calendar" />
                            15 May 2023
                          </span>
                        </div>
                        <h3 className="listing-title">
                          <Link to={routes.blogDetails}>
                            Badminton Gear Guide: Must-Have Equipment for Every
                            Player
                          </Link>
                        </h3>
                        <div className="listing-button read-new">
                          <ul className="nav">
                            <li>
                              <Link to="#">
                                <i className="feather-heart" />
                                45
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="feather-comments-square" />
                                45
                              </Link>
                            </li>
                          </ul>
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/clock.svg"
                              alt="User"
                            />
                            10 Min To Read
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="featured-venues-item aos" data-aos="fade-up">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            src="assets/img/venues/venues-08.jpg"
                            alt="User"
                          />
                        </Link>
                        <div className="fav-item-venues news-sports">
                          <span className="tag tag-blue">Sports Activites</span>
                          <div className="list-reviews coche-star">
                            <Link to="#" className="fav-icon">
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="listing-content news-content">
                        <div className="listing-venue-owner listing-dates">
                          <Link to="#" className="navigation">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-03.jpg"
                              alt="User"
                            />
                            Nichols
                          </Link>
                          <span>
                            <i className="feather-calendar" />
                            16 Jun 2023
                          </span>
                        </div>
                        <h3 className="listing-title">
                          <Link to={routes.blogDetails}>
                            Badminton Techniques: Mastering the Smash, Drop
                            Shot, and Clear{" "}
                          </Link>
                        </h3>
                        <div className="listing-button read-new">
                          <ul className="nav">
                            <li>
                              <Link to="#">
                                <i className="feather-heart" />
                                35
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="feather-comments-square" />
                                35
                              </Link>
                            </li>
                          </ul>
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/clock.svg"
                              alt="Icon"
                            />
                            12 Min To Read
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="featured-venues-item aos" data-aos="fade-up">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            src="assets/img/venues/venues-09.jpg"
                            alt="Venue"
                          />
                        </Link>
                        <div className="fav-item-venues news-sports">
                          <span className="tag tag-blue">Rules of Game</span>
                          <div className="list-reviews coche-star">
                            <Link to="#" className="fav-icon">
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="listing-content news-content">
                        <div className="listing-venue-owner listing-dates">
                          <Link to="#" className="navigation">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-06.jpg"
                              alt="User"
                            />
                            Joanna Le
                          </Link>
                          <span>
                            <i className="feather-calendar" />
                            11 May 2023
                          </span>
                        </div>
                        <h3 className="listing-title">
                          <Link to={routes.blogDetails}>
                            The Evolution of Badminton:From Backyard Fun to
                            Olympic Sport
                          </Link>
                        </h3>
                        <div className="listing-button read-new">
                          <ul className="nav">
                            <li>
                              <Link to="#">
                                <i className="feather-heart" />
                                25
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="feather-comments-square" />
                                25
                              </Link>
                            </li>
                          </ul>
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/clock.svg"
                              alt="Clock"
                            />
                            14 Min To Read
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="featured-venues-item aos" data-aos="fade-up">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.blogDetails}>
                          <ImageWithBasePath
                            src="assets/img/venues/venues-08.jpg"
                            alt="Venue"
                          />
                        </Link>
                        <div className="fav-item-venues news-sports">
                          <span className="tag tag-blue">Sports Activites</span>
                          <div className="list-reviews coche-star">
                            <Link to="#" className="fav-icon">
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="listing-content news-content">
                        <div className="listing-venue-owner listing-dates">
                          <Link to="#" className="navigation">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-01.jpg"
                              alt="User"
                            />
                            Mart Sublin
                          </Link>
                          <span>
                            <i className="feather-calendar" />
                            12 May 2023
                          </span>
                        </div>
                        <h3 className="listing-title">
                          <Link to={routes.blogDetails}>
                            Sports Make Us A Lot Stronger And Healthier Than We
                            Think
                          </Link>
                        </h3>
                        <div className="listing-button read-new">
                          <ul className="nav">
                            <li>
                              <Link to="#">
                                <i className="feather-heart" />
                                35
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="feather-comments-square" />
                                35
                              </Link>
                            </li>
                          </ul>
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/clock.svg"
                              alt="Clock"
                            />
                            12 Min To Read
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </Slider>
              </div>
            </div>
          </div>
          
          <div className="view-all text-center aos" data-aos="fade-up">
            <Link
              to={routes.blogGrid}
              className="btn btn-secondary d-inline-flex align-items-center"
            >
              View All News{" "}
              <span className="lh-1">
                <i className="feather-arrow-right-circle ms-2" />
              </span>
            </Link>
          </div>
         
        </div>
      </section>
       */}
      {/* Newsletter */}
      {/* <section className="section newsletter-sport">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="subscribe-style aos" data-aos="fade-up">
                <div className="banner-blk">
                  <ImageWithBasePath
                    src="assets/img/subscribe-bg.jpg"
                    className="img-fluid"
                    alt="Subscribe"
                  />
                </div>
                <div className="banner-info ">
                  <ImageWithBasePath
                    src="assets/img/icons/subscribe.svg"
                    className="img-fluid"
                    alt="Subscribe"
                  />
                  <h2>Subscribe to Newsletter</h2>
                  <p>Just for you, exciting badminton news updates.</p>
                  <div className="subscribe-blk bg-white">
                    <div className="input-group align-items-center">
                      <i className="feather-mail" />
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        aria-label="email"
                      />
                      <div className="subscribe-btn-grp">
                        <input
                          type="submit"
                          className="btn btn-secondary"
                          defaultValue="Subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Newsletter */}
    </>
  );
};

export default Home;
