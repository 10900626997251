import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link, redirect, useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { all_routes } from "../router/all_routes";
import axios from "axios";
import { API_URL, IMG_URL } from "../../ApiUrl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, isWithinInterval } from "date-fns";
import { Slot } from "yet-another-react-lightbox/*";
import { jwtDecode } from "jwt-decode";

interface BookData {
  _id: string;
  venue_id: string;
  date: string;
  slots: Slot[];
  startTime: string;
  endTime: string;
  price: string;
  isBooked: string;
}

type TimeSlot = {
  slot_id: any;
  time: string;
  isActive: boolean;
  isChecked: boolean;
};

interface VenueData {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  amenities: string;
  activities: string;
  category: string;
  images: string;
  src: string;
  _id: string;
}

interface JwtPayload {
  first_name: string;
  userID: string;
}

const CoachTimeDate = () => {
  const routes = all_routes;
  const [venueData, setVenueData] = useState<VenueData | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const endDate = addDays(new Date(), 14);
  const [bookData, setBookData] = useState<BookData[]>([]);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [userData, setUserData] = useState<JwtPayload | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  // console.log(id, "this id the id of the venue");

  const isDateSelectable = (date: Date): boolean => {
    return isWithinInterval(date, { start: new Date(), end: endDate });
  };

  const getDayClassName = (date: Date): string => {
    return isWithinInterval(date, { start: new Date(), end: endDate })
      ? "react-datepicker__day--highlighted-custom"
      : "";
  };

  const fetchCoaches = async (date: Date) => {
    try {
      const slotId = id;
      const dateData = { date: date.toISOString().split("T")[0] }; // format date to YYYY-MM-DD

      const response = await axios.get(
        `${API_URL}/venue/fetch-slot/${slotId}`,
        {
          params: dateData,
        }
      );
      // console.log(response, "hi-=-=-=-=-=-=-=-=-=-=-=response");

      const fetchVenueId = async () => {
        try {
          const response = await axios.get(`${API_URL}/venue/individual/${id}`);
          const venueData = response.data.venue;
          setVenueData(venueData);
        } catch (error) {
          console.error("Error fetching venues:", error);
        }
      };
      await fetchVenueId();

      const bookData = response.data.data;
      if (Array.isArray(bookData)) {
        const mappedData = bookData.map((book: any) => ({
          date: book.date,
          _id: book.id,
          venue_id: book.venue_id,
          slots: book.slots,
        }));
        setBookData(mappedData);
      } else {
        setBookData(bookData);
      }

      // console.log("this is the data -=-=-=-=-=-=", bookData);

      const newTimeSlots = bookData.slots.map((slot: any) => ({
        time: `${slot.startTime}-${slot.endTime}`,
        slot_id: slot._id,
        isActive: !slot.isBooked,
        isChecked: false,
      }));
      setTimeSlots(newTimeSlots);
      console.log("Updated time slots", newTimeSlots);
    } catch (error) {
      console.error("Error fetching coaches:", error);
    }
  };

  useEffect(() => {
    fetchCoaches(new Date());
  }, []);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      setStartDate(date);
      fetchCoaches(date);
    }
  };

  // const handleSlotClick = (index: number) => {
  //   setTimeSlots((prevSlots) => {
  //     const newSlots = [...prevSlots];
  //     const clickedSlot = newSlots[index];

  //     if (clickedSlot.isActive) {
  //       // if (!clickedSlot.isChecked) {
  //         clickedSlot.isChecked = !clickedSlot.isChecked;
  //       // }
  //     }
  //     return newSlots;
  //   });
  //   console.log("Updated time slots after click", timeSlots);
  // };

  const handleSlotClick = (index:any) => {
    setTimeSlots((prevSlots) => {
      return prevSlots.map((slot, i) => {
        if (i === index && slot.isActive) {
          // Toggle isChecked for the clicked slot if it's active
          return {
            ...slot,
            isChecked: !slot.isChecked
          };
        }
        return slot;
      });
    });
  };

  useEffect(() => {
    const getTokenFromStorage = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode<JwtPayload>(token); // Use jwtDecode instead of jwt_decode
        console.log(decodedToken, "our local data of user");
        setUserData(decodedToken);
      } else {
        console.log("No token found");
        return;
      }
    };
    getTokenFromStorage();
  }, []);

  const [newSelectedTimeId, setNewSelectedTimeId] = useState<number>([]);

  useEffect(() => {
    const totalPrice = timeSlots
      .filter((slot: any) => slot.isChecked)
      .reduce((total: number, slot: TimeSlot) => {
        const slotIndex = timeSlots.findIndex((ts) => ts === slot);
        const price = parseFloat(bookData.slots[slotIndex]?.price || "0");
        return total + price;
      }, 0);

    setNewSelectedTimeId(totalPrice);
  }, [timeSlots]);

  const [timeSlotId, setTimeSlotId] = useState<any[]>();

  useEffect(() => {
    const timeSlotId = timeSlots
      .filter((slot) => slot.isChecked)
      .map((slot) => slot.slot_id);
    // .join(", ");

    setTimeSlotId(timeSlotId);
  }, [timeSlots]);

  // const SlotTimeArr = [timeSlotId];
  // console.log("this is slot id of me", timeSlotId);
  // console.log("this is slot id of me", typeof SlotTimeArr);

  // const selectedTodayDate = new Date("Fri May 24 2024");
  const todayDate = selectedDate.toLocaleDateString("en-CA");
  // console.log("this is today date", todayDate);

  const data = {
    user_id: userData?.userID,
    venue_id: venueData?._id,
    date: todayDate,
    slotsBooked: timeSlotId,
    total_price: newSelectedTimeId,
  };
  const [inputData, setInputdata] = useState(data);

  const handleData = (e: any) => {
    setInputdata({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    try {
      console.log("final data sent", {
        user_id: userData?.userID,
        venue_id: venueData?._id,
        date: todayDate,
        slotsBooked: timeSlotId,
        total_price: newSelectedTimeId,
      });
      e.preventDefault();
      axios
        .post(`${API_URL}/booking/add`, {
          user_id: userData?.userID,
          venue_id: venueData?._id,
          date: todayDate,
          slotsBooked: timeSlotId,
          total_price: newSelectedTimeId,
        })
        .then((response) => {
          console.log(response.data);
          alert("Slot Booked");
          navigate(`/sportsvenue/venue-confirm/${id}`);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  // console.log(".............userid", userData?.userID);
  // console.log("---------venueid", venueData?._id);
  // console.log(
  //   ",,,,,,,,,,,,,bookid",
  //   timeSlots
  //     .filter((slot: any) => slot.isChecked)
  //     .map((slot: any) => slot.slot_id)
  //     .join(", ")
  // );
  // console.log(".........Date", selectedDate.toDateString());
  // console.log(".........Price ", newSelectedTimeId);
  // console.log(
  //   "...............price",
  //   timeSlots
  //     .filter((slot: any) => slot.isChecked)
  //     .reduce((total: number, slot: TimeSlot) => {
  //       const slotIndex = timeSlots.findIndex((ts) => ts === slot);
  //       const price = parseFloat(bookData.slots[slotIndex]?.price || "0");
  //       return total + price;
  //     }, 0)
  // );
  // console.log(timeSlotId, "slot iddddddddddddddddddddddddddd");

  const featuredVenuesSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    margin: 20,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <>
        <div className="breadcrumb mb-0">
          <span className="primary-right-round" />
          <div className="container">
            <h1 className="text-white">Book Now</h1>
            <ul>
              <li>
                <Link to={routes.home}>Home</Link>
              </li>
              <li>Book Now</li>
            </ul>
          </div>
        </div>

        <section className="booking-steps py-30">
          <div className="container">
            <ul className="d-xl-flex justify-content-center align-items-center">
              <li className="active">
                <h5>
                  <Link to={`/sportsvenue/venue-timedate/${id}`}>
                    <span>1</span>Time &amp; Date
                  </Link>
                </h5>
              </li>

              <li>
                <h5>
                  <Link to={`/sportsvenue/venue-confirm/${id}`}>
                    <span>2</span>Order Confirmation
                  </Link>
                </h5>
              </li>
              <li>
                <h5>
                  <Link to={`/sportsvenue/venue-payment/${id}`}>
                    <span>3</span>Payment
                  </Link>
                </h5>
              </li>
            </ul>
          </div>
        </section>

        <div className="content">
          <div className="container">
            <section className="card mb-40">
              <div className="text-center mb-40">
                <h3 className="mb-1">Time &amp; Date</h3>
                <p className="sub-title">
                  Book your training session at a time and date that suits your
                  needs.
                </p>
              </div>
              <div className="master-academy dull-whitesmoke-bg card">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <div className="d-sm-flex justify-content-start align-items-center">
                    <Link to="#">
                      <ImageWithBasePath
                        className="corner-radius-10 imgwidth"
                        src={
                          venueData?.images?.[0].src
                            ? `${IMG_URL}/${venueData?.images?.[0].src}`
                            : "/assets/img/no_image.png"
                        }
                        alt="User"
                      />
                    </Link>

                    <div className="info">
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <span className="text-white dark-yellow-bg color-white me-2 d-flex justify-content-center align-items-center">
                          4.5
                        </span>
                        <span>300 Reviews</span>
                      </div>
                      <h3 className="mb-2"> {venueData?.name}</h3>
                    </div>
                  </div>
                  <div className="white-bg">
                    <p className="mb-1">Starts From</p>
                    <h3 className="d-inline-block primary-text mb-0">
                      {bookData?.slots?.[0]?.price}
                    </h3>
                    <span>/hr</span>
                  </div>
                </div>
              </div>
            </section>
            <div className="row text-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <div className="card time-date-card">
                  <section className="booking-date">
                    <div className="list-unstyled owl-carousel date-slider owl-theme mb-40">
                      <div className="booking-date-item">
                        <DatePicker
                          selected={startDate}
                          onChange={handleDateChange}
                          filterDate={isDateSelectable}
                          dayClassName={getDayClassName}
                          showPopperArrow={true}
                          inline={false}
                          showIcon={true}
                          placeholderText="Select a date"
                        />
                      </div>
                    </div>
                    <div className="row">
                      {timeSlots.map((slot, index) => (
                        <div key={index} className="col-12 col-sm-4 col-md-3">
                          <div
                            className={`time-slot ${slot.isChecked ? "checked" : ""} ${slot.isActive ? "active" : ""}`}
                            onClick={() => handleSlotClick(index)}
                           >
                            <div className="booking-info">
                              <span className="time">{slot.time}</span>
                              <div className="price-container">
                                <span className="price">
                                  <span className="per-hour">₹</span>
                                  {
                                    bookData.slots[timeSlots.indexOf(slot)]?.price
                                  }
                                </span>
                              </div>
                            </div>

                            <i className="fa-regular fa-check-circle" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <aside className="card booking-details">
                  <h3 className="border-bottom">Booking Details</h3>
                  <ul>
                    <li>
                      <i className="feather-calendar me-2" />
                      {selectedDate.toDateString()}
                    </li>
                    <li>
                      <i className="feather-clock me-2" />
                      Slots:{" "}
                      {timeSlots
                        .filter((slot) => slot.isChecked)
                        .map((slot) => slot.time)
                        .join(", ")}
                    </li>
                    <li>
                      <i className="feather-clock me-2" />
                      Total Hour:{" "}
                      {timeSlots.filter((slot) => slot.isChecked).length} Hrs
                    </li>
                  </ul>

                  <div className="d-grid btn-block">
                    <button type="button" className="btn btn-primary">
                      Subtotal: ₹
                      {timeSlots
                        .filter((slot) => slot.isChecked)
                        .reduce((total, slot) => {
                          const slotIndex = timeSlots.findIndex(
                            (ts) => ts === slot
                          );
                          const price = parseFloat(
                            bookData.slots[slotIndex]?.price || "0"
                          );
                          return total + price;
                        }, 0)}
                    </button>
                  </div>
                </aside>
              </div>
            </div>
            <div className="text-center btn-row">
              <Link
                className="btn btn-primary me-3 btn-icon"
                to={`/sports-venue/venue-details/${id}`}
              >
                <i className="feather-arrow-left-circle me-1" /> Back
              </Link>
              <Link
                className="btn btn-secondary btn-icon"
                // to={`/coaches/coach-order-confirm/${id}`}
                onClick={handleSubmit}
                to={""}
              >
                Next <i className="feather-arrow-right-circle ms-1" />
              </Link>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CoachTimeDate;
// function setUserData(decodedToken: JwtPayload) {
//   throw new Error("Function not implemented.");
// }
