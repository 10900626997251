

import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import axios from "axios";
import { API_URL, IMG_URL } from "../../ApiUrl";
// import data from '../../../public/assets/img/featured'

interface Trainer {
  trainer_name: string;
  duration: string;
  focus_area: string;
  price: number;
  _id: number;
  profile_picture: string[];
  src: string;
}
interface FilterData {
  trainer_name: string;
  duration: string;
  focus_area: string;
  price: number;
  _id: number;
  profile_picture: string[];
  src: string;
}

const BlogList = () => {
  const routes = all_routes;
  const [selectedItems, setSelectedItems] = useState(Array(9).fill(false));
  const [trainer, setTrainer] = useState<Trainer[]>([]);
  const [selectedSort, setSelectedSort] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [filterTrainer, setFilterTrainer] = useState<FilterData[]>([]);
  const [trainerPrice, setTrainerPrice] = useState<FilterData[]>([]);
  const [name, setName] = useState<FilterData[]>([]);
  const [data, setData] = useState([]);
  const [selectedlocation, setSelectedLocation] = useState<string | null>(null);


  useEffect(() => {

    const fetchTrainer = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/PersonalTraining/fetchAll`
        );
        const trainerData = response.data.data;
        // console.log("Response:", response);

        const mappedData = trainerData.map((trainer: any) => ({
          trainer_name: trainer.trainer_name,
          duration: trainer.duration,
          focus_area: trainer.focus_area,
          price: trainer.price,
          _id: trainer._id,
          profile_picture: trainer.profile_picture,
        }));
        setTrainer(mappedData);

      } catch (error) {
        console.error("Error fetching trainer:", error);
      }
    };

    fetchTrainer();

  }, []);

  useEffect(()=>{

    const areaMap = trainer.map((t: any) => ({
      name: t.focus_area
    }))

    const allNames = areaMap.flatMap(item => item.name);
    // console.log("-=-=-=-mmmmmmmmmmmmmmmmm=-=-=-=-=-=", allNames.filter((item, index) => allNames.indexOf(item) === index))
    const updatedNames = allNames.filter((item, index) => allNames.indexOf(item) === index);
    setName(updatedNames);
  },[trainer]);

  // console.log(selectedSort,"low price &  price");
  // console.log(selectedCategory,"selectedCategory-selectedCategory");
  // console.log(trainer,"trainer-trainer");
  // console.log(trainerPrice,"trainer price-trainer price");

  useEffect(() => {
    if (selectedSort) {
      if(selectedCategory){
        console.log("this is selectedCategory",selectedCategory);
        if(selectedSort.name ==="low price"){
          const filterData = filterTrainer.filter((trainer: any) => trainer.price <= 50)
          console.log("hihihihihihihihihih",filterData)
          setTrainerPrice(filterData)
        }else{
          const filterData = filterTrainer.filter((trainer: any) => trainer.price > 50)
          console.log("hihihihihihihihihih",filterData)
          setTrainerPrice(filterData)
        }
      }else{
        if(selectedSort.name ==="low price"){
          const filterData = trainer.filter((trainer: any) => trainer.price <= 50)
          console.log("hihihihihihihihihih",filterData)
          setFilterTrainer(filterData)
        }else{
          const filterData = trainer.filter((trainer: any) => trainer.price > 50)
          console.log("hihihihihihihihihih",filterData)
          setFilterTrainer(filterData)
        }
      }
    }
  }, [selectedSort]);


  useEffect(() => {
    if (selectedCategory) {
      const filteredData = trainer.filter((t: any) => t.focus_area.includes(selectedCategory));
      console.log("filteredData", filteredData);
      setFilterTrainer(filteredData);

    }
  }, [selectedCategory])

  // console.log("this is trainer-===========", name)
  // console.log(selectedCategory, "this is focus_area")
  // console.log(filterTrainer, "this is trainer data that is filtered")



  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };


  const sortOptions = [{ name: "low price" }, { name: "high price" }];
  const sortLocation = [{ location: "location" }, { location: "location" }];

console.log("filterTrainer",filterTrainer);

  return (
    <div>
      <section className="breadcrumb breadcrumb-list mb-0">
        <span className="primary-right-round" />
        <div className="container">
          <h1 className="text-white">Personal Training</h1>
          <ul>
            <li>
              <Link to={routes.home}>Home</Link>
            </li>
            <li>Personal Training</li>
          </ul>
        </div>
      </section>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sortby-section">
                <div className="sorting-info">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                      <div className="count-search">
                        <p>
                          <span>{selectedCategory?filterTrainer.length:trainer.length}</span> Trainer are listed
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                      <div className="sortby-filter-group">
                        <div className="grid-listview">
                          <ul className="nav">
                            <li>
                              <span>View as</span>
                            </li>
                            <li>
                              <Link to={""} className="active">
                                <ImageWithBasePath
                                  src="assets/img/icons/sort-01.svg"
                                  alt="Icon"
                                />
                              </Link>
                            </li>
                            {/* <li>
                              <Link to={routes.coachesList}>
                                <ImageWithBasePath
                                  src="assets/img/icons/sort-02.svg"
                                  alt="Icon"
                                />
                              </Link>
                            </li> */}

                            {/* <div className="sorting-select">
                              <Dropdown
                                value={setSelectedLocation}
                                // onChange={handleCategoryChange}
                                options={sortLocation}
                                placeholder={<ImageWithBasePath
                                  src="assets/img/icons/sort-03.svg"
                                  alt="Icon"
                                />}
                                className="select custom-select-list w-100"
                              />
                            </div> */}
                          </ul>
                        </div>
                        <div className="sortbyset">
                          <div className="sorting-select">
                            <Dropdown
                              value={selectedCategory}
                              onChange={(e) => setSelectedCategory(e.value)}
                              options={name.map((trainer, index) => ({
                                value: trainer,
                                label: trainer
                              }))}

                              placeholder="category"
                              className="select custom-select-list w-100"
                            />
                          </div>
                          <div className="sorting-select">
                            <Dropdown
                              value={selectedSort}
                              onChange={(e) => setSelectedSort(e.value)}
                              options={sortOptions}
                              optionLabel="name"
                              placeholder="Price"
                              className="select custom-select-list w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {selectedCategory||selectedSort?
              selectedCategory&&selectedSort?
              trainerPrice.map((trainer, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="featured-venues-item">
                    <div className="listing-item listing-item-grid">
                      <div className="listing-img" style={{ height: "316px" }}>
                        {/* <Link to={routes.coachDetail}>
                        <ImageWithBasePath
                          src={`assets/img/featured/${coach.profile}`}
                          alt="Venue"
                        />
                      </Link> */}

                        <Link to={`/personal-training/trainer/${trainer._id}`}>
                          {/* <>{console.log(trainer.profile_picture, "hiiiiiiiiiiiiii helooooo")}</> */}
                          <ImageWithBasePath
                            src={
                              trainer.profile_picture
                                ? `${IMG_URL}/${trainer.profile_picture}`
                                : "/assets/img/no_image.png"
                            }
                            alt="user"
                          />
                        </Link>

                        <div
                          className="fav-item-venues"
                          onClick={() => handleItemClick(index)}
                        >
                          <span className="tag tag-blue">Professional</span>
                          <div className="list-reviews coche-star">
                            <Link
                              to="#"
                              className={`fav-icon ${selectedItems[index] ? "selected" : ""
                                }`}
                            >
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                        <div className="hour-list">
                          <h5 className="tag tag-primary">
                            ${trainer.price} <span></span>
                          </h5>
                        </div>
                      </div>
                      <div className="listing-content">
                        <h3 className="listing-title">
                          <Link to={`/personal-training/trainer/${trainer._id}`}>
                            {trainer.trainer_name}
                          </Link>
                        </h3>
                        <ul className="mb-2">
                          <li>
                            <span>Duration: {trainer.duration}</span>
                          </li>
                        </ul>
                        <div className="listing-details-group">
                          <p> Focus-Area: {trainer.focus_area}</p>
                        </div>
                        <div className="coach-btn">
                          <ul>
                            <li>
                              <Link
                                to={`/personal-training/trainer/${trainer._id}`}
                                className="btn btn-primary w-100"
                              >
                                <i className="feather-eye me-2" />
                                View Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={routes.coachDetails}
                                className="btn btn-secondary w-100"
                              >
                                <i className="feather-calendar me-2" />
                                Book Now
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="avalbity-review">
                          <ul>
                            <li>
                              <div className="avalibity-date">
                                <span>
                                  <i className="feather-calendar" />
                                </span>
                                <div className="avalibity-datecontent">
                                  <h6>Next Availability</h6>
                                  {/* <h5>{coach.availability}</h5> */}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list-reviews mb-0">
                                <div className="d-flex align-items-center">
                                  <span className="rating-bg">4.5</span>
                                  <span>80 Reviews</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              :filterTrainer.map((trainer, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="featured-venues-item">
                    <div className="listing-item listing-item-grid">
                      <div className="listing-img" style={{ height: "316px" }}>
                        {/* <Link to={routes.coachDetail}>
                        <ImageWithBasePath
                          src={`assets/img/featured/${coach.profile}`}
                          alt="Venue"
                        />
                      </Link> */}

                        <Link to={`/personal-training/trainer/${trainer._id}`}>
                          {/* <>{console.log(trainer.profile_picture, "hiiiiiiiiiiiiii helooooo")}</> */}
                          <ImageWithBasePath
                            src={
                              trainer.profile_picture
                                ? `${IMG_URL}/${trainer.profile_picture}`
                                : "/assets/img/no_image.png"
                            }
                            alt="user"
                          />
                        </Link>

                        <div
                          className="fav-item-venues"
                          onClick={() => handleItemClick(index)}
                        >
                          <span className="tag tag-blue">Professional</span>
                          <div className="list-reviews coche-star">
                            <Link
                              to="#"
                              className={`fav-icon ${selectedItems[index] ? "selected" : ""
                                }`}
                            >
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                        <div className="hour-list">
                          <h5 className="tag tag-primary">
                            ${trainer.price} <span></span>
                          </h5>
                        </div>
                      </div>
                      <div className="listing-content">
                        <h3 className="listing-title">
                          <Link to={`/personal-training/trainer/${trainer._id}`}>
                            {trainer.trainer_name}
                          </Link>
                        </h3>
                        <ul className="mb-2">
                          <li>
                            <span>Duration: {trainer.duration}</span>
                          </li>
                        </ul>
                        <div className="listing-details-group">
                          <p> Focus-Area: {trainer.focus_area}</p>
                        </div>
                        <div className="coach-btn">
                          <ul>
                            <li>
                              <Link
                                to={`/personal-training/trainer/${trainer._id}`}
                                className="btn btn-primary w-100"
                              >
                                <i className="feather-eye me-2" />
                                View Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={routes.coachDetails}
                                className="btn btn-secondary w-100"
                              >
                                <i className="feather-calendar me-2" />
                                Book Now
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="avalbity-review">
                          <ul>
                            <li>
                              <div className="avalibity-date">
                                <span>
                                  <i className="feather-calendar" />
                                </span>
                                <div className="avalibity-datecontent">
                                  <h6>Next Availability</h6>
                                  {/* <h5>{coach.availability}</h5> */}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list-reviews mb-0">
                                <div className="d-flex align-items-center">
                                  <span className="rating-bg">4.5</span>
                                  <span>80 Reviews</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              :
              trainer.map((trainer, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="featured-venues-item">
                    <div className="listing-item listing-item-grid">
                      <div className="listing-img" style={{ height: "316px" }}>
                        {/* <Link to={routes.coachDetail}>
                        <ImageWithBasePath
                          src={`assets/img/featured/${coach.profile}`}
                          alt="Venue"
                        />
                      </Link> */}

                        <Link to={`/personal-training/trainer/${trainer._id}`}>
                          {/* <>{console.log(trainer.profile_picture, "hiiiiiiiiiiiiii helooooo")}</> */}
                          <ImageWithBasePath
                            src={
                              trainer.profile_picture
                                ? `${IMG_URL}/${trainer.profile_picture}`
                                : "/assets/img/no_image.png"

                            }
                            alt="user"
                          />
                        </Link>

                        <div
                          className="fav-item-venues"
                          onClick={() => handleItemClick(index)}
                        >
                          <span className="tag tag-blue">Professional</span>
                          <div className="list-reviews coche-star">
                            <Link
                              to="#"
                              className={`fav-icon ${selectedItems[index] ? "selected" : ""
                                }`}
                            >
                              <i className="feather-heart" />
                            </Link>
                          </div>
                        </div>
                        <div className="hour-list">
                          <h5 className="tag tag-primary">
                            ${trainer.price} <span></span>
                          </h5>
                        </div>
                      </div>
                      <div className="listing-content">
                        <h3 className="listing-title">
                          <Link to={`/personal-training/trainer/${trainer._id}`}>
                            {trainer.trainer_name}
                          </Link>
                        </h3>
                        <ul className="mb-2">
                          <li>
                            <span>Duration: {trainer.duration}</span>
                          </li>
                        </ul>
                        <div className="listing-details-group">
                          <p> Focus-Area: {trainer.focus_area}</p>
                        </div>
                        <div className="coach-btn">
                          <ul>
                            <li>
                              <Link
                                to={`/personal-training/trainer/${trainer._id}`}
                                className="btn btn-primary w-100"
                              >
                                <i className="feather-eye me-2" />
                                View Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={routes.coachDetails}
                                className="btn btn-secondary w-100"
                              >
                                <i className="feather-calendar me-2" />
                                Book Now
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="avalbity-review">
                          <ul>
                            <li>
                              <div className="avalibity-date">
                                <span>
                                  <i className="feather-calendar" />
                                </span>
                                <div className="avalibity-datecontent">
                                  <h6>Next Availability</h6>
                                  {/* <h5>{coach.availability}</h5> */}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list-reviews mb-0">
                                <div className="d-flex align-items-center">
                                  <span className="rating-bg">4.5</span>
                                  <span>80 Reviews</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="col-12 text-center mt-3">
            <Link to="#" className="btn btn-load">
              Load More Coaches{" "}
              <ImageWithBasePath
                src="assets/img/icons/u_plus-square.svg"
                className="ms-2"
                alt="Icon"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
